.details-sport {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto calc(100vh - 213px);
  margin: 0;

  .header {
    position: relative;
    display: grid;
    grid-template-columns: auto 1fr;
    background-color: #2855a7;
    color: #fff;

    .btn-back {
      justify-self: start;
      align-self: center;
      height: 50px;
      width: 50px;
      border: 1px solid white;
      border-radius: 5px;
    }

    .ctn-center {
      display: grid;
      justify-content: center;
      align-items: center;
      text-align: center;

      &.gap {
        gap: 4px;
      }

      .tournament {
        font-size: 14px;
        opacity: 1;
        font-family: 'f-normal', Georgia, sans-serif;
      }

      .match {
        font-size: 18px;
        font-family: 'f-bold', Georgia, sans-serif;
        display: flex;
        flex-direction: column;
        gap: 4px;
        align-items: center;

        img {
          height: 24px;
          aspect-ratio: 1;
        }

        h4 {
          font-size: inherit;
          font-family: inherit;
          margin: 0px;
        }
      }

      span {
        font-size: 11px;
        opacity: 0.5;
      }

      p {
        font-size: 16px;
        margin: 0;
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          width: 20px;
          height: 20px;
        }
      }

      .time {
        font-size: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        font-family: 'f-thin', Georgia, sans-serif;

        span {
          padding: 2px 8px;
          background-color: white;
          border-radius: 3px;
          line-height: 1.2;
          opacity: 1;
          color: #2855a7;
        }
      }

      .count {
        font-family: 'f-bold', Georgia, sans-serif;
        font-size: 14px;
        line-height: 1.2;
        opacity: 1;
      }
    }
  }

  .content {
    height: 100%;
    width: 100%;

    .menu {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 10px;
      justify-content: center;
      padding: 15px 18%;
      width: 100%;
      margin: auto;

      .btn-outline-secondary {
        min-height: 30px;
        padding: 5px 20px;
        font-size: 12px;

        &:hover,
        &.active {
          background-color: #2855a7;
          border-color: #2855a7;
          color: #fff;
        }
      }
    }

    .ctn-odd {
      height: calc(100% - 95px);
      padding: 10px;
      width: 100%;
      display: grid;
      gap: 10px;
      align-content: flex-start;
      overflow-x: hidden;
      scrollbar-width: thin;
      padding-bottom: 50px;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        background: #fff;
      }

      &::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 5px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #ccc;
      }

      .market {
        display: grid;

        .m-title {
          font-size: 13px;
        }

        .group-odd {
          display: flex;
          width: 100%;
          gap: 5px;
          justify-content: space-between;
          flex-wrap: wrap !important;

          .btn-odd {
            background-color: #2855a7;
            display: flex;
            justify-content: space-between;
            color: #fff;
            min-height: 35px;
            border-radius: 0px;
            line-height: 1.5;
            padding: 5px;
            font-size: 12px;
            gap: 4px;
            align-items: center;
            flex: 1 1 30%;

            &:hover {
              background-color: #ffc000;
            }

            &.marked-btn {
              background-color: #ffc000;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .details-sport {
    padding-left: 0;
    width: 100%;
    grid-template-rows: auto auto;

    .header{
      background-color: inherit;
      color: var(--header-text-color);
      grid-template-columns: 1fr;
      z-index: 0;
      padding: 20px 0px;

      .ctn-center .count {
        background-color: #2855a7;
        padding: 4px;
        color: white;
        border-radius: 3px;
        min-width: 50px;
        justify-self: center;
        grid-row: 1;
      }

      .ctn-center .time {
        color: #ffc000;
        display: grid;
        font-size: 14px;
        font-family: 'f-normal', Georgia, sans-serif;
      }
    }

    .content {
      height: fit-content;
      padding-top: 0px;

      .menu {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
        gap: 5px;
        justify-content: center;
        padding: 15px 10px;
        width: 100%;
        margin: auto;
  
        .btn-outline-secondary {
          height: 45px !important;
          padding: 5px 20px;
          font-size: 12px;
        }
      }

      .ctn-odd {
        height: fit-content;
        padding: 0px;
        overflow-y: auto;
        row-gap: 20px;
        grid-row-gap: 20px;
        grid-template-columns: 100%;
        margin-bottom: 80px;
    
        .market {
          background-color: #2855a7;
          padding: 12px 8px;
          border-radius: 5px;

          .m-title {
            color: white;
            font-family: 'f-bold', Georgia, sans-serif;
            font-size: 14px;
            margin-bottom: 4px;
            margin-left: 4px;
          }
          .group-odd {
            height: auto;
            display: grid;
            grid-template-columns: 1fr 1fr auto;
            column-gap: 8px;
            grid-column-gap: 8px;

            .btn-odd{
              background-color: #11387D;
              border-radius: 3px;
              display: grid;
              grid-template-columns: 1fr;
              padding: 8px;
              width: 100%;

              &:nth-child(3n + 1):last-child {
                  grid-column-start: 1;
                  grid-column-end: 4;
              }

              &:nth-child(3n + 2):last-child {
                grid-column-start: 2;
                grid-column-end: 4;
              }

              &:nth-child(3n) {
                min-width: 20vw;
                max-width: 30vw;
              }
              

              span {
                font-family: 'f-bold', Georgia, sans-serif;
                word-break: break-word;

                &:first-child {
                  color: hwb(0 100% 0% / 0.5);
                  font-size: 12px;
                  text-align: center;
                }

                &:nth-child(2){
                  font-size: 16px;
                }
              }

              &:not(.marked-btn):hover {
                background-color:#11387D;
              }
            }
          }
        }
      }
    }

  }
}
